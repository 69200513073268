import React from "react"

const Preloader = () => (
  <div id="preloader">
    <div id="preloader-circle">
      <span></span>
      <span></span>
    </div>
  </div>
)

export default Preloader
