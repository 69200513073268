import React from "react"
import logo from "../../Images/medialinq-logo.png"

const MedialikeFooter = () => (
  <footer>
    <div className="shape-top"></div>
    <div className="container">

      <div className="top-footer">
        <div className="row">

          <div className="col-md-4">
            <div className="footer-logo">
              <img src={logo} className="img-fluid" alt="Img"/>
            </div>
            <p>
              Mikrodonationssystemet MediaLinq gör det möjligt att med några snabba knapptryckningar ge bidrag till
              artiklar och motsvarande på nätet.
            </p>
          </div>



          <div className="col-md-2">
            <h4 className="footer-title">Sidor</h4>
            <ul className="footer-links">
              <li><a href="/index.html">Startsidan</a></li>
              <li><a href="/donator/index.html">Donator</a></li>
              <li><a href="/mottagare/index.html">Mottagare</a></li>
              <li><a href="/faqs/index.html">Vanliga frågor</a></li>
            </ul>
          </div>


          <div className="col-md-2">
            <h4 className="footer-title">Användarkonto</h4>
            <ul className="footer-links">
              <li><a href="https://www.medialinq.se/app/login">Logga in</a></li>
              <li><a href="https://www.medialinq.se/app/register">Registrera dig</a></li>
            </ul>
          </div>


        </div>
      </div>


      <div className="copyrights">
        <p>Copyright Net Media Systems MD AB © 2020</p>
      </div>

    </div>
  </footer>
)

export default MedialikeFooter
