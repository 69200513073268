import React from "react"
import shape from "../../Images/shapes/price-shape.svg"

const PageTitle = (props) => (
  <div className="page-header">
    <div className="container">
      <div className="title-box">
        <h1 className="title">{props.pagename.long}</h1>
        <div className="breadcrumb">
                        <span>
                          <a href={"/"} title="Homepage"><i className="ti ti-home"></i>&nbsp;&nbsp;Startsida</a>
                        </span>
          <span className="bread-sep">&nbsp; | &nbsp;</span>
          <span>{props.pagename.short}</span>
        </div>
      </div>
    </div>
    <div className="shape-bottom">
      <img src={shape} alt="shape" className="bottom-shape img-fluid" />
    </div>
  </div>
)

export default PageTitle
