import React from "react"
import ResponsiveLayout from "../components/ResponsiveLayout"
import ContactSection from "../components/ResponsiveLayout/contactSection"
import PageTitle from "../components/ResponsiveLayout/pageTitle"
import ResponsiveLayoutSection from "../components/ResponsiveLayout/responsiveLayoutSection"
import FaqSection from "../components/ResponsiveLayout/faqSection"

const Faqs = (props) => {

  let menuPages = [
          { title: "Sidor",
            data:
            [
              { title: "Startsida", data: {href: "/index.html"} },
              { title: "Donator", data: {href: "/donator/index.html"} },
              { title: "Nudgewall", data: {href: "/nudgewall/index.html"} },
              { title: "Nätskribent", data: {href: "/mottagare/index.html"} },
              { title: "Vanliga frågor", data: {href: "/faqs/index.html"} }
            ]
          },
          { title: "FAQ", data: {"dataScrollNav": "1"}},
          { title: "Kontakt", data: {"dataScrollNav": "2"}}
      ]

  return (
      <ResponsiveLayout {...props} pages={menuPages}>
        <PageTitle {...props} pagename={{ short: "FAQ", long: "Vanliga frågor - FAQ"
        }}  />
        <ResponsiveLayoutSection dataScrollNav="1" sectionId="faqs">
          <FaqSection {...props}/>
        </ResponsiveLayoutSection>
        <ResponsiveLayoutSection dataScrollNav="2" sectionId="contact">
          <ContactSection {...props}/>
        </ResponsiveLayoutSection>
      </ResponsiveLayout>
  )
}

export default Faqs
