import React, { useEffect, useRef, useState } from "react"
import { Helmet } from "react-helmet"

import Footer from "../Footer"

import { NavigationProvider } from "../navigation-context"
import ResponsiveHeader from "./responsive-header"
import "./css/animate.min.css"
import "./css/flaticon.css"
import "./css/fontawesome.min.css"
import "./css/lightcase.min.css"
import "./css/owl.carousel.min.css"
import "./css/themify-icons.css"
import "./global.css"
import Preloader from "./preloader"
import { withPrefix } from "gatsby-link"
import useScript from "../../hooks/useScript"
import BackToTop from "./backToTop"
import MedialikeFooter from "./medialikeFooter"

// Global styles and component-specific styles.

//import * as styles from "./main.module.css"

const ResponsiveLayout = (props) => {

  useScript(withPrefix("/js/jquery.min.js"));
  useScript(withPrefix("/js/scrollIt.min.js"));
  useScript(withPrefix("/js/wow.min.js"));
  useScript(withPrefix("/js/owl.carousel.min.js"));
  useScript(withPrefix("/js/lightcase.min.js"));
  useScript(withPrefix("/js/script.js"));

  return (<NavigationProvider>
    <Helmet title="MediaLinq" />
    <Preloader />
    <div>
      <ResponsiveHeader  {...props} pages={props.pages} />
      <div>
        <main>{props.children}</main>
      </div>
      <MedialikeFooter></MedialikeFooter>
    </div>
    <BackToTop />
  </NavigationProvider>)
}

export default ResponsiveLayout
