import React from "react"
import envelope from "../../Images/icons/envelope.png"
import { Accordion, Card, Button } from "react-bootstrap"
import faqImg from "../../Images/ml-faq.png"

const FaqSection = () => (
    <div className="container">
      <div className="section-header">
        <h2>Vanliga frågor - FAQ</h2>
        <p>
          Här har vi samlat några vanliga frågor som kan uppstå när du använder MediaLinq. Vi hoppas att de svar vi ger
          hjälper dig att komma vidare,
          men om de inte gör det så skicka din fråga till support@medialinq.se
        </p> <p style={{marginTop: "0.5em"}}>
        I ditt mejl ange gärna vad du använder:
        1) Typ av enhet (dator, platta .mobil),
        2) Webbläsare (t.ex. Chrome, Firefox, Edge, Safari, Opera etc.)
        3) Operativsystem (t.ex. Windows, MacOS, iOS, Android, Linux)
      </p><p style={{marginTop: "0.5em"}}>
        Läs gärna först den <a href={"https://netmediasystems.se/att-bli-medlem"}>detaljerade beskrivningen</a> av hur man blir medlem och donator i MediaLinq eller se vår <a href={"https://fairsharemediaworld.se/medialike-registrering1"}>video</a>
        (5 minuter).
      </p>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="img-box">
            <img src={faqImg} className="img-fluid" alt="Img"/>
          </div>
        </div>
        <div className="col-md-6 faq">

          <Accordion defaultActiveKey="0">
            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                  När jag klickar på den gröna knappen kommer jag till en inloggningssida, men jag har inga
                  inloggningsuppgifter
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="0">
                <Card.Body>Du får inloggningsuppgifter när du blir medlem. Klicka på länken "registrera dig" så kommer du till
                  registreringsformuläret.</Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                  Mitt användarnamn och lösenord fungerar inte
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="1">
                <Card.Body>Du måste använda det användarnamn eller den e-postadress och det lösenord som du angav när du
                  registrerade dig i MediaLinq. Inget annat användarnamn eller lösenord.</Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="2">
                  Jag har glömt mitt lösenord
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="2">
                <Card.Body>Klicka på länken "Har du glömt lösenordet?" i inloggningsformuläret och du kommer till formuläret för
                  glömt lösenord. Där anger du ditt användarnamn eller din E-post.och klickar på "Återställ mitt
                  lösenord". Du får då ett mejl till den e-postadress du angav när du registrerade dig. I mejlet finns
                  en länk som du klickar på och kommer till en sida där du kan ange ett nytt lösenord.</Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="3">
                  Jag har glömt mitt användarnamn
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="3">
                <Card.Body>Logga in med din e-post istället. Du kommer till din profilsida (MinSida) där du ser ser vilket
                  användarnamn du har.</Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="4">
                  Jag har glömt både användarnamn och vilken e-postadress jag använde vid registreringen
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="4">
                <Card.Body>Klicka på länken "Har du glömt lösenordet?" i inloggningsformuläret och du kommer till formuläret för
                  glömt lösenord. Där provar du med de olika E-postadresser du har.och klickar på "Återställ mitt
                  lösenord" efter varje. När du träffar rätt får du ett mejl till den e-postadress du angav när du
                  registrerade dig. Annars får du inget mejl. I mejlet finns en länk som du klickar på och kommer till
                  en sida där du ska ange ett nytt lösenord. Du kommer sedan tillbaka till inloggningssidan där du
                  loggar in med e-postadressen och lösenordet. Då visas din profilsida (MinSida) där du kan se ditt
                  användarnamn och din kontoställning. Du är också nu inloggad med den webbläsare du använde och kan
                  donera med 2 klick.</Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="5">
                  Jag får inget mejl vid registrering eller lösenordsbyte (Alternativ 1)
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="5">
                <Card.Body>Kolla din skräppost</Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="6">
                  Jag får inget mejl vid registrering eller lösenordsbyte (Alternativ 2)
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="6">
                <Card.Body>Du har angivit någon annans e-postadress eller så existerar inte den adress du skrev. Skriv din
                  korrekta e-postadress!</Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="7">
                  Länken i e-postmeddelandet fungerar inte
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="7">
                <Card.Body>Det har gått mer än 1 timme sedan du registrerade dig i MediaLinq och ditt registreringsförsök har
                  raderats. Gör ny registrering!</Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="8">
                  Jag har registrerat mig och klickat på länken i e-postmeddelandet, men kan ändå inte donera med 2
                  klick
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="8">
                <Card.Body>Du har glömt att logga in! Gör det!</Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="9">
                  Jag har registrerat mig och klickat på länken i e-postmeddelandet och jag har loggat in, men kan
                  ändå inte donera med 2 klick D.v.s. jag kommer till inloggningsformuläret när jag klickar på den
                  gröna knappen
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="9">
                <Card.Body>Du har troligen loggat in med en annan webbläsare än den du använder när du ser den gröna knappen! Det
                  kan hända om ditt e-postprogram öppnar en annan webbläsare än den som du just nu surfar med. Logga
                  bara in med ditt användarnamn och ditt lösenord.</Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="10">
                  Jag har registrerat mig och loggat in på min dator, men när jag ska donera med min mobil/platta
                  krävs jag ändå på inloggning
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="10">
                <Card.Body>Det är helt korrekt då man måste logga in på varje webbläsare som man använder. Dock endast första
                  gången, sedan behöver man aldrig göra det om man inte klickar på länken "Logga ut från denna enhet"
                  som finns på MinSida.</Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="11">
                  Jag har donerat och fått donationen bekräftad men ser i listan över transaktioner (På MinSida) att
                  status är rödmarkerat
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="11">
                <Card.Body>Du har inte tillräcklig behållning på ditt donationskonto för att donationen ska överföras till
                  mottagaren. Fyll på kontot med Swish eller bankgiro. När behållningen blir tilräcklig överförs
                  donationen automatiskt och statusmarkeringen blir grön.</Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="12">
                  Jag vill fylla på med Swish men hittar inte MediaLinqs Swishnummer på betalsidan
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="12">
                <Card.Body>Det numret behövs inte! Ange bara ditt EGET Swishnummer i fältet.</Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="13">
                  Jag vill fylla på med bankgiro och undrar om jag måste ange mitt användarnam (och e-post)?
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="13">
                <Card.Body>Nej! Du anger bara den OCR-kod som MediaLinq visar. Den är unik för dig och nödvändig för att din
                  påfyllning ska föras till just ditt donationskonto. Inbetalning med angiven OCR-kod är enda
                  möjligheten att fylla på med bankgiro.</Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="14">
                  Jag har fyllt på med bankgiro och fortfarande efter 3 dagar så har mitt donationskonto inte ökat?
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="14">
                <Card.Body>Helt normalt då det tar minst en dag och längre vid helger innan MediaLinq får infomationen från
                  Bankgirot.</Card.Body>
              </Accordion.Collapse>
            </Card>


          </Accordion>


        </div>
      </div>
    </div>
)

export default FaqSection
