import React from "react"
import logo from "../../Images/medialinq-logo.png"
import "./global.css"

const ResponsiveHeader = (props) => {

  let pagesList = props.pages.map(function(page){

    if (page.data.dataScrollNav)
      return(<li key={"option"+page.title} className="nav-item"><a href="#" data-scroll-nav={page.data.dataScrollNav}>{page.title}</a></li>);
    else {
      let dropdownPagesList = page.data.map(function(subPage) {
        if (subPage.data.dataScrollNav)
          return (
            <a key={"option"+subPage.title} className="dropdown-item" href="#" data-scroll-nav={subPage.data.dataScrollNav}>{subPage.title}</a>);
        else
          return (<a key={"option"+subPage.title} className="dropdown-item" href={subPage.data.href}>{subPage.title}</a>)
      })

      return (<li key={"option"+page.title} className="nav-item dropdown">
        <a className="dropdown-toggle" href="#" id="dropdownMenuButton2" data-toggle="dropdown"
           aria-haspopup="true" aria-expanded="false">Sidor</a>
        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton2">
          {dropdownPagesList}
        </div>
      </li>);
    }
  })

  return (
    <header className="header-area" id="header-area">
      <nav className="navbar navbar-expand-md fixed-top">
        <div className="container">
          <div className="site-logo"><a className="navbar-brand" href="/index.html"><img src={logo} className="img-fluid"
                                                                                        alt="Img"/></a></div>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
                  aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"><i className="ti-menu"></i></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <ul className="navbar-nav">
              {pagesList}
            </ul>

          </div>
        </div>
      </nav>
    </header>
  )
}

export default ResponsiveHeader
