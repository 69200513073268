import React from "react"
import envelope from "../../Images/icons/envelope.png"

const ContactSection = () => (
    <div className="container">
      <div className="row">

        <div className="col-md-5">
          <div className="section-header-style2">
            <h2>Kontakta oss</h2>
            <p>
              Ta gärna kontakt med oss om du har några frågor eller vill veta mer om MediaLinq.
            </p>
          </div>
          <div className="contact-details">


        <div style={{display: "flex"}}>
            <div className="contact-block" style={{marginRight: "20px"}}>
              <h4>Email</h4>
              <div>
                <img src={envelope}
                     style={{width: "50px", float: "left", paddingLeft: "0px", paddingRight: "10px"}} />
                <p>
                  <span><a href="mailto:info@medialinq.se">info@medialinq.se</a></span>
                </p>
              </div>
            </div>

          <div className="contact-block">
            <h4>Support</h4>
            <div>
              <img src={envelope}
                   style={{width: "50px", float: "left", paddingLeft: "0px", paddingRight: "10px"}} />
              <p>
                <span><a href="mailto:support@medialinq.se">support@medialinq.se</a></span>
              </p>
            </div>
          </div>
        </div>
        </div>
        </div>

      </div>
    </div>
)

export default ContactSection
